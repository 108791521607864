/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Datepicker,
  StyledReactSelect,
  theme,
} from '@fountain/fountain-ui-components';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { EventAvailableSlot } from 'api-clients/monolith';
import { useSimpleToggle } from 'hooks';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState, VFC } from 'react';
import { classNames } from 'react-extras';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Error } from 'components/Error';
import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { getMinDate } from 'containers/ScheduleApplicantModal/components/AddAvailabilityModal/utils';
import InfoIcon from 'images/InfoIcon';

import { NO_OPTIONS, repeatEvent, useFrequencyOptions } from '../constants';
import { messages } from '../messages';
import { availability, generateAvailabilityTimes } from '../utils';
import { useSetAvailabilities } from './hooks/useSetAvailabilities';
import { useSplitOptions } from './hooks/useSplitOptions';
import { useStyles } from './styles';
import { DateSelectProps, EventDateTimeProps } from './types';

export const EventDateTime: VFC<EventDateTimeProps> = ({
  handleChange,
  availableSlot,
  errors,
  series,
  adaptiveEvents,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const currentUser = useSelector(makeSelectWhoami());
  const locale = useSelector(makeSelectLocale());
  const { time_zone: timeZone } = currentUser;
  const minDate = getMinDate(timeZone);
  const DEFAULT_SPLIT_TIME = 15;
  const DEFAULT_PLUS_TIME = 15;
  const [prevStart, setPrevStart] = useState('');
  const [nextStart, setNextStart] = useState<DateSelectProps | undefined>();

  const { splitOptions, splitOption, setSplitOption } = useSplitOptions({
    startTime: availableSlot.start_time,
    endTime: availableSlot.end_time,
    selected: availableSlot.split,
  });

  const frequencyOptions = useFrequencyOptions();

  const localizationOptions = {
    hour: 'numeric' as const,
    minute: 'numeric' as const,
    timeZone,
    timeZoneName: 'short' as const,
  };

  const {
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    availDate,
    setAvailDate,
    availableStartTimes,
    availableEndTimes,
    setAvailableEndTimes,
  } = useSetAvailabilities({
    minDate,
    availableSlot,
    handleChange,
  });

  const [isAdaptive, setIsAdaptive] = useState<boolean | undefined>();

  useEffect(() => {
    if (adaptiveEvents) {
      if (typeof availableSlot?.adaptive !== 'undefined') {
        setIsAdaptive(availableSlot.adaptive);
      } else {
        setIsAdaptive(false);
      }
    }
  }, [adaptiveEvents, availableSlot]);

  useEffect(() => {
    if (typeof isAdaptive !== 'undefined') {
      handleChange({ adaptive: isAdaptive });
    }
  }, [isAdaptive, handleChange]);

  const durationInMinutes = (startTime: string, endTime: string) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const durationInMs = end.getTime() - start.getTime();
    return durationInMs / (1000 * 60);
  };

  const splitTime =
    durationInMinutes(availableSlot.start_time, availableSlot.end_time) ||
    DEFAULT_SPLIT_TIME;
  const [plusTime, setPlusTime] = useState(
    durationInMinutes(availableSlot.start_time, availableSlot.end_time) ||
      DEFAULT_PLUS_TIME,
  );

  const { showContent: showSplitOptions, toggle: handleToggle } =
    useSimpleToggle(false);

  const [splitDisabled, setSplitDisabled] = useState(
    !!availableSlot.external_id,
  );

  useEffect(() => {
    if (prevStart === availableSlot.start_time) {
      const newPlusTime = durationInMinutes(
        availableSlot.start_time,
        availableSlot.end_time,
      );
      setPlusTime(newPlusTime);
    } else {
      setPrevStart(availableSlot.start_time);
    }
  }, [
    availableSlot.end_time,
    availableSlot.start_time,
    prevStart,
    setPrevStart,
  ]);

  useEffect(() => {
    if (plusTime === NO_OPTIONS) {
      setSplitDisabled(true);
      if (showSplitOptions) {
        handleToggle();
      }
    } else {
      setSplitDisabled(!!availableSlot.external_id);
    }
  }, [plusTime, showSplitOptions, handleToggle, availableSlot.external_id]);

  const dateWithUpdatedTimeValue = (date: Date, timeValue: string) => {
    const availDateString = `${date.getFullYear()}-${(1 + date.getMonth())
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    const dateWithTime = timeValue.replace(
      /^\d{4}-\d{2}-\d{2}/,
      availDateString,
    );
    return dateWithTime;
  };

  const onStartDateChange = useCallback(
    (e: DateSelectProps) => {
      const startTime = dateWithUpdatedTimeValue(availDate, e.value);
      const startDateTime = DateTime.fromISO(startTime);
      const endTimeDateStartFrom = startDateTime.plus({
        minutes: plusTime,
      });
      const currentAvailabilityEndTimes =
        startDateTime.day < endTimeDateStartFrom.day
          ? availableEndTimes.slice(-1)
          : generateAvailabilityTimes(endTimeDateStartFrom, splitTime);
      setAvailableEndTimes(currentAvailabilityEndTimes);
      setStartTime(e);
      const firstCurrentAvailabilityEndTime = currentAvailabilityEndTimes[0];
      setEndTime(firstCurrentAvailabilityEndTime);
      handleChange({ start_time: startTime });
      handleChange({ end_time: firstCurrentAvailabilityEndTime.value });
    },
    [
      handleChange,
      availDate,
      availableEndTimes,
      setAvailableEndTimes,
      plusTime,
      setEndTime,
      setStartTime,
      splitTime,
    ],
  );

  const onEndDateChange = (e: DateSelectProps) => {
    setEndTime(e);
    const endTime = dateWithUpdatedTimeValue(availDate, e.value);
    handleChange({ end_time: endTime });
  };

  const onDateChange = (e: Date | Date[] | undefined) => {
    if (!e) return;
    const firstDate = Array.isArray(e) ? e[0] : e;
    setAvailDate(firstDate);

    const selectedDateTime = DateTime.fromISO(
      dateWithUpdatedTimeValue(firstDate, startTime.value),
    );
    const currentDateTime = DateTime.local();

    if (selectedDateTime < currentDateTime) {
      const currentAvailabilityStartTimes = availability(
        firstDate,
        currentDateTime.toString(),
      ).currentAvailabilityTimes;
      setNextStart(currentAvailabilityStartTimes[0]);
    } else {
      handleChange({
        start_time: dateWithUpdatedTimeValue(firstDate, startTime.value),
      });
      handleChange({
        end_time: dateWithUpdatedTimeValue(firstDate, endTime.value),
      });
    }
  };

  const repeatChange = (event: {
    value: 'days' | 'weeks' | 'weekdays' | '';
  }) => {
    if (event.value === '') {
      handleChange({
        frequency: undefined,
      });
    }
    handleChange({
      period: event.value as EventAvailableSlot['period'],
    });
  };

  useEffect(() => {
    handleChange({
      split: showSplitOptions && splitOption ? splitOption.value : undefined,
    });
  }, [handleChange, splitOption, showSplitOptions]);

  useEffect(() => {
    if (nextStart) {
      onStartDateChange(nextStart);
      setNextStart(undefined);
    }
  }, [nextStart, onStartDateChange]);
  const handleSplitChange = ({
    label,
    value,
  }: {
    label: string;
    value: number;
  }) => {
    setSplitOption({ label, value });
    handleChange({ split: value });
  };

  const splitTooltip = availableSlot.external_id
    ? intl.formatMessage(messages.unavailableSplitDisabled)
    : intl.formatMessage(messages.unavailableSplitShort);

  return (
    <Grid container xs={12} className={styles.rowChild} direction="row">
      <Grid item xs={12}>
        <Typography variant="h3">
          <FormattedMessage {...messages.eventTimeAndSeries} />
        </Typography>
        <Typography
          variant="body2"
          color="textPrimary"
          className={styles.description}
        >
          <FormattedMessage {...messages.eventTimeAndSeriesDescription} />
        </Typography>

        <Grid className={styles.rowChild} container direction="row" spacing={1}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body2"
              className={classNames(styles.label, styles.required)}
            >
              <FormattedMessage {...messages.date} />{' '}
            </Typography>
            <Datepicker
              locale={locale}
              minDate={minDate}
              value={availDate}
              onDateChange={onDateChange}
              renderDate={selectedDate => (
                <FormattedDate dateStyle="short" value={selectedDate} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={6}>
                <StyledReactSelect
                  label={intl.formatMessage(messages.startTime)}
                  options={availableStartTimes}
                  value={startTime}
                  onChange={onStartDateChange}
                  getOptionLabel={(option: DateSelectProps) =>
                    intl.formatTime(option.value, localizationOptions)
                  }
                  className={styles.nestedRequired}
                  error={Boolean(errors?.start_time)}
                />
                {errors?.start_time && <Error error={errors.start_time} />}
              </Grid>
              <Grid item xs={6}>
                <StyledReactSelect
                  label={intl.formatMessage(messages.endTime)}
                  options={availableEndTimes}
                  getOptionLabel={(option: DateSelectProps) =>
                    intl.formatTime(option.value, localizationOptions)
                  }
                  value={endTime}
                  isDisabled={series}
                  onChange={onEndDateChange}
                  className={series ? styles.disable : styles.nestedRequired}
                  error={Boolean(errors?.end_time)}
                />
                {errors?.end_time && <Error error={errors.end_time} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={styles.rowChild} container direction="row" spacing={1}>
          <Grid item xs={12} sm={6}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12} sm={6}>
                <StyledReactSelect
                  label={intl.formatMessage(messages.repeat)}
                  options={repeatEvent}
                  getOptionLabel={(option: { label: string }) => option.label}
                  getOptionValue={(option: { value: string }) => option.value}
                  value={repeatEvent.find(
                    item => item.value === availableSlot.period,
                  )}
                  isDisabled={!!availableSlot.external_id}
                  className={availableSlot.external_id ? styles.disable : ''}
                  onChange={(event: {
                    value: 'days' | 'weeks' | 'weekdays' | '';
                  }) => repeatChange(event)}
                  autoWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledReactSelect
                  label={intl.formatMessage(messages.endRepeatAfter)}
                  options={frequencyOptions}
                  value={
                    !!availableSlot.period && !!availableSlot.frequency
                      ? {
                          label: intl.formatMessage(messages.frequencyOptions, {
                            count: availableSlot.frequency,
                          }),
                          value: availableSlot.frequency,
                        }
                      : null
                  }
                  getOptionLabel={(option: { label: string }) => option.label}
                  getOptionValue={(option: { value: string }) => option.value}
                  isDisabled={!availableSlot.period}
                  onChange={(event: { value: number }) =>
                    handleChange({
                      frequency: event.value,
                    })
                  }
                  className={
                    availableSlot.period
                      ? styles.nestedRequired
                      : styles.disable
                  }
                  autoWidth
                  error={Boolean(errors?.frequency)}
                />
                {errors?.frequency && <Error error={errors.frequency} />}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="row" className={styles.tall}>
              <Tooltip title={splitTooltip}>
                <Grid item xs={6} className={styles.tall}>
                  <div className={styles.toggleContainer}>
                    <FormControl
                      className={styles.toggleWrapper}
                      disabled={splitDisabled}
                    >
                      <FormControlLabel
                        className={styles.splitFormControl}
                        control={
                          <Switch
                            onChange={handleToggle}
                            checked={showSplitOptions}
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            className={styles.toggleLabel}
                          >
                            <FormattedMessage {...messages.splitIntoSmaller} />
                          </Typography>
                        }
                      />
                    </FormControl>
                  </div>
                </Grid>
              </Tooltip>
              <Grid item xs={6}>
                {showSplitOptions && (
                  <label>
                    <span className={styles.required}>
                      <FormattedMessage {...messages.splitInto} />
                    </span>

                    <StyledReactSelect
                      options={splitOptions}
                      value={splitOption}
                      isDisabled={!!availableSlot.external_id}
                      className={
                        availableSlot.external_id ? styles.disable : ''
                      }
                      getOptionLabel={(option: { label: string }) =>
                        option.label
                      }
                      getOptionValue={(option: { value: string }) =>
                        option.value
                      }
                      onChange={handleSplitChange}
                    />
                  </label>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {adaptiveEvents && typeof isAdaptive !== 'undefined' && (
          <Grid
            className={styles.rowChild}
            container
            direction="row"
            spacing={1}
          >
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    className={styles.switchAligned}
                    checked={isAdaptive}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setIsAdaptive(event.target.checked);
                    }}
                    data-testid="adaptive-switch"
                  />
                }
                label={
                  <Tooltip title={intl.formatMessage(messages.adaptiveTooltip)}>
                    <Typography variant="body2" color="textPrimary">
                      <FormattedMessage {...messages.adaptive} />
                      <InfoIcon
                        color={theme.palette.common.gray800}
                        className={styles.tooltipIcon}
                      />
                    </Typography>
                  </Tooltip>
                }
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
/* eslint-enable jsx-a11y/label-has-associated-control */
