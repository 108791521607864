import { makeStyles, Theme } from '@material-ui/core/styles';

interface ChatProgressorStylesProps {
  currentStep: number;
}

export const useAgentListStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(5),
    gap: theme.spacing(3),
  },
  header: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderRadius: '12px',
    border: theme.customBorders.gray200,
    boxShadow: 'none',
    minHeight: '80px',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    '& button': {
      fontSize: theme.typography.fontSize,
    },
  },
  agentButton: {
    textTransform: 'none',
  },
  button: {
    padding: theme.spacing(1.5, 3),
    borderRadius: '5px',
    height: '40px',
    fontSize: '14px',
  },
  customMenu: {
    boxShadow: theme.customShadows.card,
    border: theme.customBorders.gray200,
    borderRadius: theme.shape.borderRadius,
    minWidth: 100,
    backgroundColor: theme.palette.background.paper,
  },
  menuItem: {
    padding: theme.spacing(1, 2),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  iconButtonHighlighted: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.action.selected,
  },
  agentName: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  draftLabel: {
    borderRadius: '5px',
    padding: theme.spacing(1),
    background: theme.palette.common.gray200,
  },
}));

export const useConfirmationModalStyles = makeStyles(theme => ({
  modalContainer: {
    [theme.breakpoints.up('sm')]: {
      width: '448px',
    },
    gap: '24px',
  },
  modalTitle: {
    borderBottom: theme.customBorders.gray200,
    padding: '16px 32px',
    '& > h2': {
      fontSize: '20px',
      color: theme.palette.common.gray800,
      lineHeight: '28px',
    },
  },
  dialogContent: {
    padding: '0 32px',
  },
  modalContentText: {
    margin: 0,
    color: theme.palette.common.gray800,
    fontSize: '14px',
  },
  dialogActions: {
    padding: '12px 32px',
    borderTop: theme.customBorders.gray200,
    background: theme.palette.common.gray100,
  },
  btnDelete: {
    background: theme.palette.common.red400,
    color: theme.palette.common.white,
    border: 0,
    '&:hover': {
      background: theme.palette.common.red400,
      color: theme.palette.common.white,
      border: 0,
    },
  },
  btnContinue: {
    background: theme.palette.common.blue400,
    color: theme.palette.common.white,
    border: 0,
    '&:hover': {
      background: theme.palette.common.blue400,
      color: theme.palette.common.white,
      border: 0,
    },
  },
  btnPublish: {
    background: theme.palette.common.blue400,
    color: theme.palette.common.white,
    border: 0,
    '&:hover': {
      background: theme.palette.common.blue400,
      color: theme.palette.common.white,
      border: 0,
    },
  },
  btnUnpublish: {
    background: theme.palette.common.red400,
    color: theme.palette.common.white,
    border: 0,
    '&:hover': {
      background: theme.palette.common.red400,
      color: theme.palette.common.white,
      border: 0,
    },
  },
  btnCancel: {
    background: theme.palette.common.white,
    color: theme.palette.common.gray800,
    border: 0,
    '&:hover': {
      background: theme.palette.common.white,
      color: theme.palette.common.gray800,
      border: 0,
    },
  },
  embedCodeContainer: {
    [theme.breakpoints.up('sm')]: {
      width: '600px',
    },
    paddingBottom: theme.spacing(3),
    gap: '24px',
  },
  copyCodeContainer: {
    position: 'relative',
    border: theme.customBorders.gray200,
  },
  copyCodeBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
    background: theme.palette.common.white,
    color: theme.palette.common.gray800,
    border: 0,
    '&:hover': {
      background: theme.palette.common.white,
      color: theme.palette.common.gray800,
      border: 0,
    },
  },
  copyBtnLabel: {
    display: 'flex',
    gap: '8px',
    '& > svg': {
      fontSize: '16px',
    },
  },
  embedCodeInput: {
    '& textarea': {
      backgroundColor: theme.palette.common.gray100,
      padding: theme.spacing(1),
    },
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: 'grey',
  },
}));

export const useChatProgressorStyles = makeStyles<
  Theme,
  ChatProgressorStylesProps
>(theme => ({
  chatbotProgressor: {
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 0),
    },
  },
  progressorHeader: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: theme.customBorders.gray300,
  },
  stepProgressBar: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    padding: theme.spacing(2, 5),
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    gap: theme.spacing(1),
  },
  step: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    lineHeight: '24px',
    color: theme.palette.common.white,
    backgroundColor: '#ccc',
    fontSize: theme.typography.h5.fontSize,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  active: {
    backgroundColor: theme.palette.common.blue400,
  },
  inactive: {
    backgroundColor: theme.palette.common.gray500,
  },
  backToAgentList: {
    padding: theme.spacing(2, 5),
    '& > button': {
      padding: 0,
      minWidth: 'fit-content',
    },
  },
  widgetContainer: {
    minWidth: '456px',
    display: 'flex',
    padding: theme.spacing(0, 5),
  },
  container: {
    width: '100%',
    padding: theme.spacing(0, 5),
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    height: 'calc(100% - 60px)',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
    // Calculating height by subtracting:
    // progress bar height, padding bottom, footer buttons
    height: 'calc(100vh - 117px - 16px - 72px)',
  },
  stepContent: {
    overflowY: 'auto',
    paddingBottom: theme.spacing(2),
  },
}));

export const usePersonalizeAIAgentStyles = makeStyles(theme => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    paddingRight: theme.spacing(2),
  },
  header: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.25),
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  label: {
    color: theme.palette.common.gray800,
  },
  chooseBrandInput: {
    padding: theme.spacing(1, 0, 0, 4),
  },
  menuItem: {
    color: theme.palette.common.gray800,
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(1.5),
    '&.Mui-selected': {
      backgroundColor: theme.palette.common.blue100,
      color: theme.palette.common.blue400,
    },
  },
  chooseApplyBot: {
    padding: theme.spacing(1, 0, 0, 4),
  },
  botLabel: {
    padding: theme.spacing(1, 0, 0, 5.5),
  },
  root: {
    padding: theme.spacing(1.5),
    '&:focus': {
      background: theme.palette.common.white,
    },
  },
  disabled: {
    opacity: 0.5,
    color: theme.palette.common.gray500,
  },
}));

export const useAIAgentFunctionalitiesStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  header: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  formInput: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  contactInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  menuItem: {
    color: theme.palette.common.gray800,
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(1.5),
    '&.Mui-selected': {
      backgroundColor: theme.palette.common.blue100,
      color: theme.palette.common.blue400,
    },
  },
  root: {
    padding: theme.spacing(1.5),
    '&:focus': {
      background: theme.palette.common.white,
    },
  },
  label: {
    color: theme.palette.common.gray800,
  },
  customWebsiteSection: {
    paddingLeft: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(2),
  },
  customWebsiteEvenRow: {
    backgroundColor: theme.palette.common.gray100,
    padding: theme.spacing(1, 2),
    position: 'relative',
    '& *': {
      padding: 0,
    },
  },
  customWebsiteOddRow: {
    padding: theme.spacing(1, 2),
    backgroundColor: 'inherit',
    position: 'relative',
    '& *': {
      padding: 0,
    },
  },
  actionButtons: {
    display: 'flex',
    gap: '8px',
    position: 'absolute',
    top: '50%',
    bottom: '50%',
    right: '12px',
  },
  cancelBtn: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.gray300}`,
    color: theme.palette.common.gray800,
    '&:hover': {
      background: theme.palette.common.white,
    },
  },
  customWebsiteLabel: {
    height: '32px',
    color: theme.palette.common.gray800,
  },
  addWebsite: {
    padding: theme.spacing(2, 3),
    gap: '16px',
    backgroundColor: theme.palette.common.gray100,
  },
  visibilityLabel: {
    alignItems: 'flex-start',
    paddingLeft: theme.spacing(1),
  },
  addWebsiteInput: {
    backgroundColor: theme.palette.common.white,
  },
}));

export const useFooterStyles = makeStyles(theme => ({
  navigationButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  draftNavigationButtons: {
    justifyContent: 'space-between',
  },
  previousBtn: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.gray300}`,
    color: theme.palette.common.gray800,
    '&:hover': {
      background: theme.palette.common.white,
    },
  },
  button: {
    padding: theme.spacing(1.5, 3),
    borderRadius: '5px',
    height: '40px',
    fontSize: '14px',
    boxShadow: 'none',
  },
  submitButton: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  draftButton: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.blue400}`,
    color: theme.palette.common.blue400,
    '&:hover': {
      background: theme.palette.common.white,
    },
  },
}));

export const useSetupInstructionsStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(6),
    gap: theme.spacing(2),
  },
  header: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: 500,
  },
  content: {
    width: '100%',
    padding: theme.spacing(10),
    background: theme.palette.common.gray100,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  section: {
    display: 'flex',
    gap: theme.spacing(2.5),
    flexDirection: 'column',
    width: 'auto',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
  },
  footer: {},
}));

export const useIphoneFrameStyles = makeStyles(theme => ({
  iphone: {
    width: '375px',
    height: '700px',
    border: `16px solid ${theme.palette.common.black}`,
    borderRadius: '36px',
    position: 'relative',
    boxShadow: '0 4px 8px rgba(0,0,0,0.5)',
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
  },
  topNotch: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '150px',
    height: '25px',
    background: theme.palette.common.black,
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
  },
  screen: {
    width: '100%',
    height: '100%',
    border: 'none',
    position: 'absolute',
    top: '30px',
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
